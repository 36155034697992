// NOTE: This file is served on both server and browser
let _, defs;
if (typeof module !== 'undefined' && module?.exports) {
    _ = require('lodash');
    defs = require('./definitions');
} else {
    defs = window.Definitions;
    _ = window._;
}

let Security = (function() {
    Security = class Security {
        // Static property (replacing initClass)
        static advisorLogoutPeriodMilliseconds = 1000 * 60 * 60 * 24 * 365 * 3; // 3 years in milliseconds

        static instance = null;

        static getInstance() {
            if(!Security.instance) {
                Security.instance = new Security();
            }
            return Security.instance;
        }

        constructor() {
            if(Security.instance) {
                return Security.instance;
            }
            Security.instance = this;
        }

        checkAny = (major, minor, rights) => {
            if (minor === '0') {
                for (const right of rights) { // DS101: Removed Array.from as for...of works directly
                    const splitRight = right.split('.');
                    if (splitRight[0] === major) return true;
                }
            }
            return false;
        };

        hasRole = (userCache, role) => {
            return userCache.systemPrivs?.roles?.includes(role) ?? false; // DS207: Simplified null check with ??
        };

        hasEstatePlanAccess = (userCache, estatePlan) => {
            // Guard clauses for null/undefined inputs
            if (!userCache || !estatePlan) return false;

            const systemPrivs = userCache.systemPrivs;
            if (!systemPrivs) return false;

            const roles = systemPrivs?.roles ?? [];

            // Check for system admin first (highest priority)
            if (this.isSysAdmin(roles)) return true;

            // Check if user is more than a customer (covers many cases)
            if (this.isMoreThanACustomer(roles) && !this.isAttorney(roles)) return true;

            // Session-based estate plan access
            if (userCache.estatePlanId === estatePlan.id) return true;

            // Attorney access check
            if (this.isAttorney(roles) && estatePlan.attorneyId === userCache.attorneyId) return true;

            // Advisor access check
            if (this.isAdvisor(roles) &&
                (estatePlan.advisorId === userCache.user?.id || estatePlan.advisorId === userCache.advisorId)) {
                return true;
            }

            // White label access check
            if (estatePlan.whiteLabelId &&
                systemPrivs.whiteLabelId !== defs.estateGuruWhiteLabelId &&
                systemPrivs.whiteLabelId !== estatePlan.whiteLabelId) {
                return false;
            }

            return false;
        };

        hasPageAccess = (userCache, requestedAccess, whiteLabelId, estatePlanId, requestedEstatePlanId) => {
            if (!userCache || !requestedAccess) return false;

            const requestedAccessArray = [].concat(requestedAccess); // Ensure array
            const systemPrivs = userCache.systemPrivs;
            if (!systemPrivs) return false;

            const rights = systemPrivs?.rights ?? [];
            const roles = systemPrivs?.roles ?? [];

            // SysAdmin bypass
            if (this.isSysAdmin(roles)) return true;

            // White label validation
            if (whiteLabelId && !this.isSysAdmin(roles)) {
                if (systemPrivs.whiteLabelId !== whiteLabelId && systemPrivs.whiteLabelId !== null) return false;
            } else if (!systemPrivs.whiteLabelId) {
                return false;
            }

            for (const requestedRight of requestedAccessArray) { // DS101: Removed Array.from
                const [major, minor] = requestedRight.split('.');
                if (
                    rights.includes(requestedRight) || // DS204: Natural order for includes
                    rights.includes(major) ||
                    this.checkAny(major, minor, rights)
                ) {
                    if (this.isMoreThanACustomer(roles) || this.isAdvisor(roles)) return true;

                    const estatePlanSections = [defs.rights.customerPages.all, defs.rights.customerCapabilities.all];
                    if (
                        estatePlanId &&
                        (!requestedEstatePlanId || requestedEstatePlanId === estatePlanId) &&
                        estatePlanSections.includes(major)
                    ) {
                        const estatePlanRoles = _.find(userCache.estatePlans, {id: estatePlanId})?.roles ?? []; // DS103: Removed __guard__
                        if (estatePlanRoles.includes(defs.estatePlanRoles.owner.name)) return true;

                        if (requestedRight === defs.rights.customerCapabilities.viewOverview) {
                            const allowedRoles = [
                                defs.estatePlanRoles.child,
                                defs.estatePlanRoles.beneficiary,
                                defs.estatePlanRoles.healthcarePOA,
                                defs.estatePlanRoles.financialPOA,
                                defs.estatePlanRoles.executor,
                                defs.estatePlanRoles.successorTrustee,
                                defs.estatePlanRoles.contingentBeneficiary,
                                defs.estatePlanRoles.guardian,
                                defs.estatePlanRoles.separatingSpouse,
                                defs.estatePlanRoles.priorSpouse
                            ];
                            if (allowedRoles.some(role => estatePlanRoles.includes(role.name))) return true;
                        }

                        if (this.hasEstatePlanPageAccess(userCache.estatePlanPrivs, requestedRight)) return true;
                    }
                }
            }
            return false;
        };

        hasEstatePlanPageAccess = (estatePlanPrivs, requestedRight) => { // TODO: Obsolete
            if (!estatePlanPrivs || !requestedRight) return false;

            for (const personId in estatePlanPrivs) {
                const privs = estatePlanPrivs[personId];
                const isOwnerDetermined = this.isOwnerDetermined(requestedRight);
                if (isOwnerDetermined && privs.pageRights?.includes(requestedRight)) return true;
            }
            return false;
        };

        hasSpecificAttorneyRights = (requestedAttorneyId, userCache) => {
            const roles = userCache.systemPrivs?.roles ?? [];
            if (this.isSysAdmin(roles) || this.isLegalOps(roles)) return true;
            return requestedAttorneyId === userCache.attorneyId && !!userCache.attorneyId;
        };

        isOwnerDetermined = (requestedRight) => {
            return [
                defs.rights.customerPages.myEstatePlan,
                defs.rights.customerPages.legalFile,
                defs.rights.customerPages.beneficiaries,
                defs.rights.customerPages.personalRepresentatives,
                defs.rights.customerPages.advisors,
                defs.rights.customerCapabilities.perDocumentAccessRights,
                defs.rights.customerCapabilities.viewLegalFile
            ].includes(requestedRight); // DS204: Natural order
        };

        isMoreThanACustomer = (userRoles) => {
            const {roles} = defs;
            return userRoles.some(role =>
                [
                    roles.sysAdmin.name,
                    roles.sysCustService.name,
                    roles.accountManager.name,
                    roles.admin.name,
                    roles.custService.name,
                    roles.attorney.name,
                    roles.legalOps.name
                ].includes(role)
            ); // DS204: Natural order
        };

        isAdvisor = (roles) => roles.includes(defs.roles.advisor.name) ?? false;
        isSysCustomerService = (roles) => roles.includes(defs.roles.sysCustService.name) ?? false;
        isAccountManager = (roles) => roles.includes(defs.roles.accountManager.name) ?? false;
        isCustService = (roles) => roles.includes(defs.roles.custService.name) ?? false;
        isAttorney = (roles) => roles.includes(defs.roles.attorney.name) ?? false;
        isLegalOps = (roles) => roles.includes(defs.roles.legalOps.name) ?? false;
        isAdmin = (roles) => roles.includes(defs.roles.admin.name) ?? false;
        isSysAdmin = (roles) => roles.includes(defs.roles.sysAdmin.name) ?? false;

        hasAccountingAccess = (whiteLabelId, userCache) => {
            if (!userCache?.systemPrivs) return false;
            const roles = userCache.systemPrivs?.roles ?? [];

            if (this.isSysAdmin(roles)) return true;
            if (whiteLabelId && userCache.systemPrivs.whiteLabelId !== whiteLabelId && userCache.systemPrivs.whiteLabelId !== null) {
                return false;
            }
            return this.isAdmin(roles);
        };

        canUseDefaultPayment = (whiteLabelId, userCache) => {
            if (!userCache?.systemPrivs) return false;
            const roles = userCache.systemPrivs?.roles ?? [];

            if (this.isSysAdmin(roles)) return true;
            if (whiteLabelId && userCache.systemPrivs.whiteLabelId !== whiteLabelId && userCache.systemPrivs.whiteLabelId !== null) {
                return false;
            }
            return this.isMoreThanACustomer(roles) || this.isAdvisor(roles);
        };

        isWhiteLabelRole = (roleName, user, whiteLabel) => {
            if (!user?.whiteLabel || !whiteLabel) return false;

            if (whiteLabel.supportMethod === 'self') {
                if (user.whiteLabel.id !== whiteLabel.id) return false;
            } else if (user.whiteLabel.id !== defs.estateGuruWhiteLabelId && user.whiteLabel.id !== whiteLabel.id) {
                return false;
            }

            return _.map(user.userRoles, 'role').includes(roleName); // DS204: Natural order
        };

        hasProfileAccess = (whiteLabelId, userCache) => this.hasPeopleAccess(whiteLabelId, userCache);

        hasPeopleAccess = (whiteLabelId, userCache) => {
            if (!userCache?.systemPrivs) return false;
            const roles = userCache.systemPrivs?.roles ?? [];

            if (this.isSysAdmin(roles)) return true;
            if (whiteLabelId && userCache.systemPrivs.whiteLabelId !== whiteLabelId && userCache.systemPrivs.whiteLabelId !== null) {
                return false;
            }
            if (this.isMoreThanACustomer(roles)) return true;

            const estatePlan = _.find(userCache.estatePlans, {id: userCache.estatePlanId});
            if (!estatePlan) return false;

            if (
                this.isAdvisor(roles) &&
                estatePlan.advisorId === userCache.user?.id &&
                (
                    Date.parse(estatePlan.createdAt) > (Date.now() - Security.advisorLogoutPeriodMilliseconds) ||
                    estatePlan.advisorRights?.some(right => [1, 2].includes(right.right))
                )
            ) {
                return true;
            }

            if (!estatePlan.asPerson) return false;
            return _.intersection(estatePlan.asPerson, [
                estatePlan.primaryOwner?.id,
                estatePlan.secondaryOwner?.id
            ]).length > 0;
        };

        getMappedAlias = (alias) => {
            return defs.estatePlanSecurityList.find(element => element.identifiedBy.includes(alias))?.alias ?? null;
        };

        hasPackageBasedOnDocumentList = (packageDef, documentList) => {
            if (!packageDef) return false;
            return packageDef?.identifyingDocuments?.some(doc => documentList.includes(doc)) ?? false; // DS103, DS204
        };

        hasEverythingDocumentAccess = (whiteLabelId, userCache, estatePlan) => {
            if (!estatePlan) return false;
            const documentList = JSON.parse(estatePlan.documentList);

            for (const alias of Object.values(defs.packageList)) { // DS101: Simplified
                const packageDef = _.find(defs.packages, {alias});
                if (!packageDef || !this.hasPackageBasedOnDocumentList(packageDef, documentList)) continue;

                const ownerIdPrimary = estatePlan.ownerIdPrimary ?? estatePlan.primaryOwner?.id;
                const ownerIdSecondary = estatePlan.ownerIdSecondary ?? estatePlan.secondaryOwner?.id;

                if (packageDef.scope === 'individual') {
                    if (!this.hasPackageAccess(whiteLabelId, userCache, estatePlan, alias, ownerIdPrimary)) return false;
                    if (ownerIdSecondary && !this.hasPackageAccess(whiteLabelId, userCache, estatePlan, alias, ownerIdSecondary)) return false;
                } else if (!this.hasPackageAccess(whiteLabelId, userCache, estatePlan, alias, null)) {
                    return false;
                }
            }
            return true;
        };

        hasPackageAccess = (whiteLabelId, userCache, backupEstatePlan, alias, ownerId) => {
            if (!userCache || !alias) return false;
            const systemPrivs = userCache.systemPrivs;
            if (!systemPrivs) return false;

            if (this.isSysAdmin(systemPrivs.roles)) return true;
            if (whiteLabelId && systemPrivs.whiteLabelId !== whiteLabelId && systemPrivs.whiteLabelId !== null) return false;

            const roles = systemPrivs?.roles ?? [];
            if (this.isMoreThanACustomer(roles)) return true;

            const estatePlan = _.find(userCache.estatePlans, {id: userCache.estatePlanId}) ?? backupEstatePlan;
            if (!estatePlan) return false;

            const mappedAlias = this.getMappedAlias(alias);
            if (
                this.isAdvisor(roles) &&
                (estatePlan.advisorId === userCache.user?.id || estatePlan.advisor?.id === userCache.user?.id)
            ) {
                const createdDate = Date.parse(estatePlan.createdAt);
                if (createdDate > (Date.now() - Security.advisorLogoutPeriodMilliseconds)) return true;

                const right = estatePlan.advisorRights?.find(r => r.advisorId === estatePlan.advisorId && r.alias === mappedAlias && r.ownerId === ownerId && r.type === 'package')?.right;
                if ([defs.accessTypes.read, defs.accessTypes.modify].includes(right)) return right;
            }

            if (!Array.isArray(estatePlan.asPerson)) return false;
            if (estatePlan.asPerson.includes(ownerId)) return true;

            const right = estatePlan.packageRights?.find(r => r.alias === mappedAlias && r.ownerId === ownerId)?.right;
            const isAnOwner = estatePlan.roles?.includes(defs.estatePlanRoles.owner.name) ?? false;
            if (isAnOwner && ownerId === null) return true;
            if (isAnOwner) return right !== defs.accessTypes.none;
            return right === defs.accessTypes.modify;
        };

        hasDocumentAccess = (userCache, documentId, whiteLabelId, estatePlanId) => { // OBSOLETE
            if (!userCache || !documentId) return false;
            const systemPrivs = userCache.systemPrivs;
            if (!systemPrivs) return false;

            if (whiteLabelId) {
                if (systemPrivs.whiteLabelId !== whiteLabelId && systemPrivs.whiteLabelId !== null) return false;
            } else if (systemPrivs.whiteLabelId) {
                return false;
            }

            if (this.isMoreThanACustomer(systemPrivs?.roles)) return defs.accessTypes.modify;
            return this.hasEstatePlanDocumentAccess(userCache?.estatePlanPrivs, documentId);
        };

        hasEstatePlanDocumentAccess = (estatePlanPrivs, documentId) => { // OBSOLETE
            if (!estatePlanPrivs || !documentId) return false;

            let bestAccess = false;
            for (const personId in estatePlanPrivs) {
                const access = estatePlanPrivs[personId].documentRights?.[documentId];
                if (access === defs.accessTypes.modify) return access;
                if (access) bestAccess = access;
            }
            return bestAccess;
        };
    }
    Security = Security.getInstance();
    return Security;
})

// Export for server and client
if (typeof module !== 'undefined' && module?.exports) {
    module.exports = new Security();
} else {
    window.Security = new Security();
}